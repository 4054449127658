.dashboard_filtration_icon {
  position: fixed !important;
  bottom: 20px;
  left: 20px;
  border-color: #1ca4c7;
}

.dashboard_filtration {
  display: flex !important;
  justify-content: left;
  min-height: 100% !important;
  padding: 0 !important;
}

.cards {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  overflow-y: scroll;
  width: 100%;
}

.scroll_filtration::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.scroll_filtration::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey;
  border-radius: 10px;
}

/* Handle */
.scroll_filtration::-webkit-scrollbar-thumb {
  background: #1ca4c7;
  border-radius: 10px;
}

/* Handle on hover */
.scroll_filtration::-webkit-scrollbar-thumb:hover {
  background: #009eb3;
}

@media screen and (max-width: 1191px) {
  .dashboard_filtration {
    position: fixed !important;
    height: 90% !important;
    border-radius: 10px;
    overflow: hidden;
    transform: translate(-50%, -10%);
    left: 50%;
    transition: width 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  }
}
@media screen and (max-width: 400px) {
  .cards {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: nowrap;
    align-items: center;
  }
}

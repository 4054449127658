.cover {
  position: relative;
  height: 400px;
  width: 100%;
  background-color: #1ca4c7;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.col_cover {
  position: relative;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.cover_txt_principe {
  font-size: 70px;
  font-weight: bold;
  color: #bef2ff;
  text-align: center;
}

.cover_txt_secondary {
  font-size: xx-large;
  font-weight: bold;
  color: #fff;
  margin-top: 20px;
}

.cover_img {
  width: 379px;
  margin-top: 67px;
  margin-left: 50px;
  /* box-shadow: 0 4px 16px 2px #000; */
}

.add_people_cover {
  position: absolute;
  height: 40px !important;
  width: 40px !important;
  /* top: 33px;
    right: 44px; */
  bottom: 50px;
  margin-left: 30px;
  z-index: 2;
  background-color: #fff;
  border-radius: 50%;
  color: #1ca4c7;
  animation: 3s add_btn infinite;
  border: 1px solid #bef2ff;
  box-shadow: #fff 2px 8px 20px 0px !important;
}

@keyframes add_btn {
  0% {
    bottom: 50px;
  }
  50% {
    bottom: 80px;
  }
  100% {
    bottom: 50px;
  }
}

@media screen and (max-width: 950px) {
  .col_cover {
    width: 80%;
  }
  .cover_img {
    margin-top: 12px;
  }
}

@media screen and (max-width: 596px) {
  .col_cover {
    width: 95%;
  }
  .cover_txt_secondary {
    text-align: center;
    font-size: x-large;
  }
  .cover_txt_principe {
    font-size: 40px;
  }
  .cover_img {
    width: 300px;
    margin-left: 0px;
  }
}
@media screen and (max-width: 408px) {
  .cover_txt_secondary {
    font-size: large;
  }
  .cover_txt_principe {
    font-size: 27px;
  }
  .cover {
    padding-bottom: 50px;
  }
}

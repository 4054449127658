@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");

* {
  font-family: "poppins", sans-serif;
}

.box {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-image: url("../../../img/bg-signin.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.section {
  position: relative;
  max-width: 400px;
  background-color: #fff;
  border: 2px solid #ffffff80;
  border-radius: 20px;
  backdrop-filter: blur(80px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 3rem;
  display: flex;
  flex-direction: column;
}

/* h1 {
  font-size: 2rem;
  color: #000;
  text-align: center;
} */

.forget {
  margin: 35px 0;
  font-size: 0.9rem;
  color: #000;
  display: flex;
  justify-content: center;
}

.forget label input {
  margin-right: 3px;
}

.button {
  width: 100%;
  height: 40px;
  border-radius: 40px;
  background-color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
}

.register {
  font-size: 0.9rem;
  color: #000;
  text-align: center;
  margin: 25px 0 10px;
}

.register p a {
  text-decoration: none;
  color: #000;
  font-weight: 600;
}

.register p a:hover {
  text-decoration: underline;
}

.findthem {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.fidthem_title {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 330px;
  align-items: center;
  align-content: center;
  justify-content: space-around;
}

.fingthem_icon_behind {
  width: 100px;
  height: 50px;
}
.fingthem_txt {
  text-align: center;
  font-size: xxx-large;
  color: #1ca4c7;
  font-weight: bold;
  padding-bottom: 20px;
  /* border-bottom: 2px solid #0D657C; */
}

.findthem_title_line {
  width: 100px;
  height: 2px;
  background-color: #0d657c;
}

.fidthem_title_line_content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fingthem_txt_underline {
  margin-top: 10px;
  color: #0d657c;
  text-align: center;
}

.category_cards {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-content: center;
  width: 100%;
}

@media screen and (max-width: 562px) {
  .card_findthem_content {
    left: 75px;
    bottom: -42px;
  }
  .card_findthem_txt_abslt {
    display: none;
  }
  .card_findthem {
    margin-top: 100px;
    margin-left: 0px;
    height: 300px;
    width: 300px;
  }
  .card_findthem_imgPrincip {
    height: 280px;
    width: 280px;
    border-radius: 50%;
  }
  .fingthem_txt {
    font-size: xx-large;
  }
  .fingthem_icon_behind {
    margin: 15px;
    width: 80px;
  }
}

.header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 80px;
  background-color: #bef2ff;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: space-around;
  z-index: 9;
  transition: all 0.5s ease-in;
}
.header_logo {
  height: 70px;
  width: 70px;
}

.header_seach_icon {
  font-size: x-large;
  color: #1ca4c7;
  margin-left: 5px;
  height: 40px !important;
  width: 40px !important;
}
.header_btn {
  height: 30px;
  width: fit-content;
  padding: 8px;
  background-color: #1ca4c7;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-direction: row;
  border-radius: 20px;
}

.header_btn:hover {
  background-color: #15738b;
}

.header_btn_txtPrincip {
  font-size: medium;
  font-weight: bold;
  margin-left: 5px;
}
.header_btn_divsecondary {
  display: flex;
  flex-direction: column;
  color: #bef2ff;
  margin-left: 5px;
}
.header_btn_txtsecondary {
  color: #bef2ff;
  font-weight: 900;
}

/* .header_btn_icon {
  color: #2789a2;
} */

.menu {
  width: 320px;
  height: 0px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 9;
  top: 80px;
  right: 0;
  transition: height 0.5s ease-in;
  overflow: hidden;
  border-style: 0px solid #16a4c7;
  border-radius: 10px;
}

.menu_content {
  display: flex !important;
  flex-direction: column;
  width: 100%;
}
.menu_content > button {
  border: 0px;
  font-size: larger;
}
.menu_mob {
  display: none !important;
}

/******* SubHeader  */
.subHeader {
  height: 40px;
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-content: center;
}
.subHeader_icons {
  color: #1ca4c7;
  font-weight: 600;
  border: 3px solid #1ca4c7;
  padding: 2px;
  border-radius: 50%;
  width: 20px !important;
  height: 20px !important;
}

.subHeader_txt {
  font-size: large !important;
}

/***Add People */
.addPeople {
  width: 320px;
  /* height: 450px; */
  height: 0px;
  background-color: #fff;
  position: fixed;
  transform: translate(-50%, -50%);
  top: 57%;
  left: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  transition: height 0.5s ease-in;
  z-index: 12;
}
.addPeople_img_abs {
  position: absolute;
  height: 70px;
  left: -13px;
  transform: rotate(19deg);
}

.addPeople_close_icon {
  margin-left: 290px;
  margin-top: 10px;
}
.addpeople_div {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.addpeople_title {
  color: #16a4c7;
  font-size: x-large;
  font-weight: bold;
}
.addpeople_title_underline {
  margin-top: 10px;
  height: 2px;
  width: 100px;
  background-color: #0d657c;
}

.btn_primary {
  font-size: large;
  font-weight: 600;
  border-radius: 20px;
  background-color: #16a4c7;
  padding: 5px;
  height: 30px;
  width: 250px;
  color: #fff;
  text-align: center;
  margin-top: 10px;
}

.addPeople_scroolItems {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 220px;
  overflow-y: scroll;
  padding-top: 40px;
  padding-bottom: 50px;
}

.form_controle_addpeople {
  width: 80%;
}

.divider_addpeople {
  margin-bottom: 10px;
  margin-top: 10px;
  width: 90%;
  margin-left: 10%;
}

.dowload_png {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-direction: row;
}

.dowload_png_txt {
  margin-bottom: 6px;
  margin-right: 12px;
}

.btn_dowlaod {
  font-size: medium;
  font-weight: 600;
  border-radius: 20px;
  background-color: #fff;
  border: 2px solid #16a4c7;
  padding: 5px;
  height: 30px;
  width: 250px;
  color: #16a4c7;
  text-align: center;
}

/*Comment Shower*/
.comment_Showed {
  width: 320px;
  /* height: 450px; */
  height: 0px;
  background-color: #fff;
  position: fixed;
  transform: translate(-50%, -50%);
  top: 57%;
  left: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  transition: height 0.5s ease-in;
  z-index: 10;
}

/***/
.search {
  width: 320px;
  /* height: 450px; */
  height: 0px;
  background-color: #fff;
  position: fixed;
  transform: translate(-50%, -50%);
  top: 57%;
  left: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  transition: height 0.5s ease-in;
  z-index: 10;
}

#error_inputs {
  color: red;
}
#success_inputs {
  color: green;
}

a {
  text-decoration: none;
}

/* textarea:focus-visible {
  outline: #1976d2 auto 1px !important;
} */
@media screen and (max-width: 700px) {
  .subHeader {
    display: none;
  }
  .menu_mob {
    display: block !important;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.empty_over {
  width: 100%;
  display: flex;
  justify-content: center;
  font-weight: bold;
  flex-direction: column;
  align-items: center;
}

.over_loading {
  left: 50%;
  background-color: #fff;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 24;
  top: 50%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  color: #fff;
  transition: all 0.5s ease-in-out;
  flex-direction: column;
  transform: translate(-50%, -50%);
}
.over_loading_img {
  height: 150px;
  width: 150px;
}

.over_loading_txt {
  font-size: large;
  font-weight: bold;
  color: #15738b;
  padding-top: 100px;
  margin-right: 14px;
}

.over_loading_txt:after {
  content: " .";
  animation: dots 1s steps(5, end) infinite;
  font-size: xxx-large;
}

@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: #16a4c7;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 #16a4c7, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 #16a4c7, 0.5em 0 0 #16a4c7;
  }
}

.over_loading_txt {
  color: #16a4c7;
  font: 300 4em/150% Impact;
  text-align: center;
  position: absolute;
  transform: translate(0px, 24px);
}

@media screen and (min-width: 1610px) {
  html {
    display: flex;
    justify-content: center;
  }
  body {
    max-width: 1610px !important;
  }
  .header {
    max-width: 1255px !important;
  }
}

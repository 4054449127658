.background_black_height {
  height: 100% !important;
}

.background_black {
  z-index: 9;
  width: 100%;
  height: 0%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.651);
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  transition: all 0.5s ease-in;
}

.position_stickly {
  position: sticky;
  top: 90px;
}

.sidenav_text span {
  font-size: large !important;
}
